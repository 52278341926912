<template>
    <div>
        <title-bar :title="$t('navigations.read')"/>
        <div v-if="rows" class="custom-table">
            <advanced-filter
                v-if="!widget"
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="['search', 'perPage', 'advancedFilter']"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}
                    {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:employee="data">
                    {{
                        data.value.employee.givenName + " " + data.value.employee.familyName
                    }}
                </template>
                <template v-slot:document_name="data">
                    <a v-if="data.value.document" target="_blank" :href="data.value.document.url">
                        {{ data.value.document.name }}
                    </a>
                </template>
                <template v-slot:acknowledged="data">
                    <b-badge :variant="data.value.acknowledged ? 'success' : 'danger'">
                        {{ data.value.acknowledged ? $t('forms.yes').toUpperCase() : $t('forms.no').toUpperCase() }}
                    </b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <router-link to="" v-if="!data.value.acknowledged" @click="showModal(data.value)"
                                     class="custom-btn">
                            <BootstrapIcon icon="check-lg" size="1x"/>
                        </router-link>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
        <b-modal
            v-model="modalShow"
            modal-class="filter-dialog"
            ok-title="Confirm"
            @ok="confirmModal()"
            @hide="modalShow = false"
        >
            <div v-if="modalDocument">
                I hereby acknowledge that I read and understood
                <a v-if="modalDocument.document" target="_blank" :href="modalDocument.document.url">
                    {{ modalDocument.document.name }}
                </a>.
            </div>
        </b-modal>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";

export default {
    name: "AdminReadAndUnderstand",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        TitleBar,
        AdvancedFilter
    },
    props: {
        acknowledgedOnly: {type: Boolean, default: false},
        widget: {type: Boolean, default: false}
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.widget ? 5 : this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            modalShow: false,
            modalDocument: null,
            filterFields: [
                {
                    label: 'forms.employee',
                    name: "employee",
                    value: null,
                    type: "employee"
                },
                {
                    label: 'forms.document',
                    name: "document",
                    value: null,
                    type: "importantUpdate",
                },
            ],
            advancedParams: [],
            sortable: {
                order: "acknowledged",
                sort: "asc",
            },
            columns: [
                {
                    label: this.$t("forms.created"),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.document"),
                    field: "document_name",
                    sortable: true,
                },
                {
                    label: this.$t("forms.employee"),
                    field: "employee",
                    sortable: true,
                },
                {
                    label: this.$t("forms.acknowledged"),
                    field: "acknowledged",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            if (this.acknowledgedOnly) {
                params.acknowledged = false
            }
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });
            this.$ReadAndUnderstand.getCollection({params}, "rud_list").then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            this.load();
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        view(url) {
            this.updateByUrl(this.$ReadAndUnderstand, url, {acknowledged: true}, this.$t("messages.acknowledged"), null, this.load, this.error);
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
        showModal(document) {
            this.modalDocument = document
            this.modalShow = true
        },
        confirmModal() {
            this.view(this.modalDocument['@id'])
            this.modalShow = false
        }
    },
};
</script>

<style lang="scss">
</style>
