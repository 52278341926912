<template>
    <b-card :header="$t('navigations.receipts')" header-tag="strong" class="receipts-dashboard">
        <receipts-table :widget="true"/>
    </b-card>
</template>

<script>
import ReceiptsTable from '@/views/Admin/Receipts/list.vue'

export default {
    components: {
        ReceiptsTable
    }
};
</script>
